@use 'styles' as theme;

.root {
  width: 100%;
  max-width: 160px;
  display: grid;
  gap: theme.size(0.5);

  @include theme.mediaTabletPortraitUp {
    max-width: 180px;
  }
}

.link {
  @include theme.outlineOnFocus($offset: theme.size(0.5));

  border-radius: theme.$borderRadiusXXS;
  position: relative;
  width: 100%;
}

.image {
  display: flex;
  padding-bottom: 3px;

  @include theme.mediaTabletPortraitUp {
    padding-bottom: 5px;
  }
}

.suffix {
  @include theme.textXSStrong;
  line-height: 1;
  text-align: center;
  text-wrap: nowrap;
  color: theme.$colorBlue;
}

.suffixCentered {
  text-align: center;
}

.white {
  color: #fff;
}

.title {
  position: absolute;
  top: 27px;
  right: -1px;
  font-size: 11px;
  color: theme.$colorBlue;
}

.partner {
  white-space: nowrap;
  transform: translateX(theme.size(0.5));
}

.partnerLabel {
  display: inline-block;
  line-height: 1;

  &::first-letter {
    text-transform: uppercase;
  }
}

.partnerLogo {
  display: inline-block;
  width: 40px;
}

.partner_gliapps {
  width: 30px;
}
.partner_DCB_2019 {
  width: 80px;
}
.partner_automanijak {
  width: 84px;
}
.partner_m1a2r39nit91ol7im {
  width: 60px;
}
.partner_mojvolan {
  width: 60px;
}
.partner_vinformer {
  width: 20px;
}
.partner_47590335 {
  width: 60px;
}
.partner_vindecodervehicle {
  width: 82px;
}

.partner_decodethis001 {
  width: 50px;
}
.partner_radon3 {
  width: 82px;
}
.partner_CARVIN {
  width: 70px;
}
.partner_sprawdzanievin {
  width: 82px;
}
.partner_DISCOUNTCHECKRO {
  width: 84px;
}
.partner_alinfrancisc {
  width: 44px;
}
.partner_REDUCERE20 {
  width: 72px;
}
.partner_AUTOVIN {
  width: 72px;
}
.partner_OLXRO,
.partner_autovit,
.partner_665089d276120 {
  width: 80px;
}
.partner_olx {
  width: 30px;
}
.partner_carzz {
  width: 52px;
}
