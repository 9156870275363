@use '~@carvertical/ui/src/styles' as theme;
@use 'sass:map';
@import './variables.module';

@mixin resetMargin {
  margin: 0;
}

@mixin unstyledList {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin unstyledButton {
  display: inline-block;
  padding: 0;
  border: 0;
  background: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.25;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
  appearance: none;
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }

  &:focus {
    outline: 0;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin unstyledInput {
  padding: 0;
  border: 0;
  border-radius: 0;
  line-height: 1;
  background: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  appearance: none;

  &:focus {
    outline: 0;
  }
}

@mixin ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin link($color: theme.$colorBlue, $decoration: underline) {
  color: $color;
  cursor: pointer;

  &:hover {
    text-decoration: $decoration !important;
  }
}

@mixin transition($properties: all, $speed: fast) {
  $transition: ();
  @each $property in $properties {
    $transition: append(
      $transition,
      (
        $property
          map-get(
            (
              fast: 100ms,
              normal: 200ms,
              slow: 400ms,
              slower: 800ms,
            ),
            $speed
          )
          ease-in-out
      ),
      $separator: comma
    );
  }
  transition: $transition;
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin circle($size) {
  @include square($size);
  border-radius: 50%;
}

@mixin absoluteCenter($size: null) {
  position: absolute;
  top: 50%;
  left: 50%;

  @if $size {
    margin: (-$size / 2) 0 0 (-$size / 2);
  } @else {
    transform: translate(-50%, -50%);
  }
}

@mixin positionFill($position: absolute, $offset: 0) {
  position: $position;
  top: $offset;
  right: $offset;
  bottom: $offset;
  left: $offset;
}

@mixin hiddenText {
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin clearFigureStyles {
  margin: 0;
}

@mixin for-phone-only {
  @media screen and (max-width: $tabletPortraitBreakpoint - 1) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media print, screen and (min-width: $tabletPortraitBreakpoint) {
    @content;
  }
}
@mixin for-phone-tablet-portrait-only {
  @media screen and (max-width: $tabletLandscapeBreakpoint - 1) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media print, screen and (min-width: $tabletLandscapeBreakpoint) {
    @content;
  }
}
@mixin for-desktop-up {
  @media screen and (min-width: $desktopBreakpoint) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media screen and (min-width: $bigDesktopBreakpoint) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

@mixin flexCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin visuallyHidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

@mixin focusOutline {
  box-shadow:
    0 0 0 3px theme.$colorWhite,
    0 0 0 5px theme.$colorBlue300;
}

@mixin increaseTargetSize($clickTargetMinimum: 44px) {
  $_insetBy: Min(0px, calc((100% - #{$clickTargetMinimum}) / 2));

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: $_insetBy;
    left: $_insetBy;
    right: $_insetBy;
    bottom: $_insetBy;
  }
}

// Typography

@mixin typography($size, $lineHeight, $weight: null) {
  font-size: $size * 1px;
  line-height: $lineHeight / $size;
  @if $weight {
    font-weight: $weight;
  }
}

@mixin headingXl {
  @include typography(32, 40, $weightBold);

  @include for-tablet-landscape-up {
    @include typography(48, 56);
  }
}

@mixin headingL {
  @include typography(32, 40, $weightBold);

  @include for-tablet-landscape-up {
    @include typography(40, 48);
  }
}

@mixin headingM {
  @include typography(24, 32, $weightBold);

  @include for-tablet-landscape-up {
    @include typography(32, 40);
  }
}

@mixin headingS {
  @include typography(20, 32, $weightSemibold);

  @include for-tablet-landscape-up {
    @include typography(24, 32, $weightBold);
  }
}

@mixin headingXs {
  @include typography(16, 24, $weightSemibold);

  @include for-tablet-landscape-up {
    @include typography(20, 32);
  }
}

@mixin subheadingL() {
  @include typography(18, 24, $weightRegular);

  @include for-tablet-landscape-up {
    @include typography(20, 32);
  }
}

@mixin bodyM {
  @include typography(16, 24, $weightRegular);
}

@mixin bodyS {
  @include typography(14, 24, $weightRegular);
}

@mixin labelL {
  @include typography(16, 24, $weightSemibold);
}

@mixin labelM {
  @include typography(14, 24, $weightSemibold);
}

@mixin labelS {
  @include typography(12, 16, $weightSemibold);
}

@mixin labelSLight {
  @include typography(12, 16, $weightRegular);
}

@mixin buttonL {
  @include typography(16, 24, $weightSemibold);
}

@mixin buttonM {
  @include typography(14, 24, $weightSemibold);
}

@mixin buttonS {
  @include typography(12, 16, $weightSemibold);
}

@mixin boxShadow($level: 1, $color: 'black', $inverted: false, $light: false) {
  $_colorBlue: #1e86ee;
  $_type: if($inverted, 'inverted', 'default');

  $_level: (
    0: (
      'default': 0 1px 5px,
      'inverted': 0 -1px 5px,
    ),
    1: (
      'default': 0px 4px 16px,
      'inverted': 0px -4px 16px,
    ),
    2: (
      'default': 0px 8px 32px -8px,
      'inverted': 0px -8px 32px -8px,
    ),
    3: (
      'default': 0px 16px 48px -8px,
      'inverted': 0px -16px 48px -8px,
    ),
  );
  $_color: (
    'black': rgba(theme.$colorBlack, if($light, 0.05, 0.1)),
    'blue': rgba($_colorBlue, if($light, 0.05, 0.15)),
  );

  box-shadow: map-get(map-get($_level, $level), $_type) map-get($_color, $color);
}

/*
  @mixin sectionVerticalPadding($direction: 'both', $sizeVariant: 'large')
  $direction: 'top', 'bottom', 'both'
  $sizeVariant: 'small', 'large'
*/
@mixin sectionVerticalPadding($direction: 'both', $sizeVariant: 'large') {
  $_mobilePadding: map.get(map.get($sizeSectionPadding, $sizeVariant), 'mobile');
  $_desktopPadding: map.get(map.get($sizeSectionPadding, $sizeVariant), 'desktop');

  @if $direction == 'top' {
    padding-top: $_mobilePadding;
  } @else if $direction == 'bottom' {
    padding-bottom: $_mobilePadding;
  } @else {
    padding: $_mobilePadding 0;
  }

  @include theme.mediaTabletLandscapeUp {
    @if $direction == 'top' {
      padding-top: $_desktopPadding;
    } @else if $direction == 'bottom' {
      padding-bottom: $_desktopPadding;
    } @else {
      padding: $_desktopPadding 0;
    }
  }
}

@mixin colouredContainer($color: theme.$colorBlue) {
  @include sectionVerticalPadding($sizeVariant: 'small', $direction: 'top');
  @include sectionVerticalPadding($sizeVariant: 'small', $direction: 'bottom');

  position: relative;
  z-index: 1;

  &::after {
    @include theme.absoluteFill;

    content: '';
    background-color: $color;
    z-index: -1;

    @include theme.mediaTabletLandscapeUp {
      border-radius: theme.$borderRadiusL;
      left: theme.$framePaddingTabletLandscapeUp;
      right: theme.$framePaddingTabletLandscapeUp;
    }

    @include theme.mediaDesktopUp {
      left: theme.$framePaddingDesktopUp;
      right: theme.$framePaddingDesktopUp;
    }
  }
}
