@use '~@carvertical/ui/src/styles' as theme;
@use '@carvertical/ui/dist/lib';
@import '~normalize.css';
@import './mixins';
@import './variables.module';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply mx-auto box-content max-w-[1408px] px-2 md:px-4 lg:px-[6.25rem];
  }
}

@layer base {
  :root {
    --headerHeight: 64px;

    --framePaddingSm: 16px;
    --framePaddingMd: 24px;
    --framePaddingLg: 32px;
  }
}

@layer utilities {
  .focusOutline {
    box-shadow:
      0 0 0 3px theme.$colorWhite,
      0 0 0 5px theme.$colorBlue300;
  }

  .hideScrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  }

  .outlineOnFocus {
    outline: 2px solid theme.$colorBlue300;
    outline-offset: 2px;
    transition: outline theme.$transitionFast;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;

  @media print {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

html,
body,
#__next {
  height: 100%;

  .iframe & {
    height: auto;
  }
}

html {
  font-family: $font;
  font-size: $fontSize;
  line-height: 1.5;
  color: theme.$colorGrey600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus-within {
    scroll-behavior: smooth;
  }
}

body {
  min-width: $minWidth;

  @include print {
    .print-hidden {
      display: none;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

strong {
  font-weight: $weightMedium;
}

h1,
h2,
h3,
h4 {
  line-height: 1.25;
  font-weight: $weightMedium;
}

pre,
code {
  font-family: $fontMonospaced;
}

#__next {
  display: flex;
  flex-direction: column;
  max-width: $maxWidth;
  margin: 0 auto;
}

@page {
  size: A4;
  margin: 1cm;
}

.Modal--open {
  @include print {
    position: static !important;
    overflow: auto !important;
  }

  #__next {
    filter: blur(5px);

    @include print {
      filter: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
